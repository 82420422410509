import { forwardRef } from "react";
import styled from "styled-components";

interface Props extends React.ComponentPropsWithRef<"label"> {
  children?: string;
}

const Label = forwardRef<HTMLLabelElement, Props>(({ ...props }, ref) => {
  return <Wrapper ref={ref} {...props} />;
});

const Wrapper = styled.label<{
  readOnly?: boolean;
}>`
  font-size: 12px;
`;

Label.displayName = "Label";
const LabelExtended = Object.assign({}, Label, { Root: Wrapper });

export { LabelExtended as Label };
