import styled, { css } from "styled-components";

type Space = "default" | "small" | "medium" | "large";

interface Props extends React.ComponentProps<"div"> {
  align?: "start" | "center" | "end";
  direction?: "row" | "column";
  gap?: number;
  justify?: string;
  space?: Space;
  textCenter?: boolean;
}

const Box = ({
  children,
  align,
  direction = "column",
  gap,
  space = "default",
  justify,
  className,
  style,
  textCenter,
}: Props) => {
  return (
    <Wrapper
      align={align}
      direction={direction}
      gap={gap}
      space={space}
      justify={justify}
      className={className}
      textCenter={textCenter}
      style={style}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  align?: string;
  direction?: string;
  gap?: number;
  space?: Space;
  justify?: string;
  textCenter?: boolean;
}>`
  display: flex;
  flex-direction: ${(p) => p.direction};
  align-items: ${(p) => p.align};
  justify-content: ${(p) => p.justify};
  ${(p) =>
    p.textCenter &&
    css`
      text-align: center;
    `};
  ${(p) => p.space === "default" && spaceDefaultStyle};
  ${(p) => p.space === "small" && spaceSmallStyle};
  ${(p) => p.space === "medium" && spaceMediumStyle};
  ${(p) => p.space === "large" && spaceLargeStyle};
  ${(p) =>
    p.gap &&
    css`
      ${p.gap}px
    `};
`;

const spaceDefaultStyle = css`
  gap: 0px;
`;

const spaceSmallStyle = css`
  gap: 0.5rem;
`;

const spaceMediumStyle = css`
  gap: 1rem;
`;

const spaceLargeStyle = css`
  gap: 1.5rem;
`;

Box.Root = Wrapper;

export { Box, type Props as BoxProps };
