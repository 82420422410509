import styled, { css } from "styled-components";

type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5";

const headingSizeMap: Record<HeadingElements, string> = {
  h1: "1.625rem",
  h2: "1.5rem",
  h3: "1.25rem",
  h4: "1.125rem",
  h5: "1rem",
};

interface Props extends React.ComponentProps<"h1"> {
  level?: HeadingElements;
}

const Heading = ({ level: element = "h1", children, className }: Props) => {
  return (
    <Wrapper as={element} className={className}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.h1<{
  as: HeadingElements;
}>`
  font-weight: 500;
  font-size: ${(p) => headingSizeMap[p.as]};
  ${(p) => p.as === "h1" && h1Styles}
`;

const h1Styles = css`
  font-size: 1.625rem;
  line-height: 2rem;
`;

Heading.Root = Wrapper;

export { Heading };
