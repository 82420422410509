import { palette } from "@plantura-garden/palette";
import Image from "next/image";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import eyeCoveredSvg from "~/assets/svgs/eye-hide.svg";
import eyeSvg from "~/assets/svgs/eye.svg";
import { Checkmark } from "../icons/validation-checkmark";
import { ExclamationMark } from "../icons/validation-exclamation";

import { Label } from "./label";

interface Props extends React.ComponentPropsWithRef<"input"> {
  children?: string;
  check?: boolean;
  exclaim?: boolean;
  password?: boolean;
  type?: "text" | "password" | "email" | "number";
  label?: string;
  defaultValue?: string | number;
}

const InputField = forwardRef<HTMLInputElement, Props>(
  ({ defaultValue, id, key, label, type = "text", ...props }, ref) => {
    const { t } = useTranslation();
    const [showPassword, showPasswordSet] = useState(false);
    const password = type === "password";

    const type_ = useMemo(() => {
      if (type === "password" && showPassword) {
        return "text";
      }
      return type;
    }, [showPassword, type]);

    const togglePassword = useCallback(() => {
      showPasswordSet((value) => !value);
    }, []);

    return (
      <Wrapper key={key} type={type}>
        <InputWrapper
          id={id}
          value={defaultValue}
          ref={ref}
          type={type_}
          placeholder={label}
          {...props}
        />
        <Label htmlFor={id}>{label}</Label>
        {(props.check || props.exclaim || password) && (
          <div className="input-field--actions">
            {password && (
              <Image
                className="input-field--password"
                src={showPassword ? eyeSvg : eyeCoveredSvg}
                width={24}
                height={24}
                alt={
                  showPassword
                    ? t("common.showPassword")
                    : t("common.hidePassword")
                }
                onClick={togglePassword}
              />
            )}
            {props.check && (
              <div className="input-field--validation">
                <Checkmark color="var(--success)" />
              </div>
            )}
            {props.exclaim && (
              <div className="input-field--validation">
                <ExclamationMark color="var(--danger)" />
              </div>
            )}
          </div>
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{
  type?: "text" | "password" | "email" | "number";
}>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  text-align: unset;

  & ${Label.Root} {
    font-size: 16px;
    color: ${palette.gray500};
    margin-left: 18px;
    transform: translateY(12px);
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transition:
      transform 300ms ease,
      font-size 300ms ease;
  }

  &:focus-within ${Label.Root} {
    transform: translateY(5px);
    font-size: 12px;
  }

  & .input-field--actions {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 1rem;
    top: 0.75rem;
    height: 24px;
    width: calc(24px * 2 + 8px);
    z-index: 1;
  }

  & .input-field--actions > * + * {
    margin-left: 0.75rem;
  }

  & .input-field--validation {
    width: 24px;
    height: 24px;
    animation: fadeIn 0.1s ease-in forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & .input-field--password {
    right: 1rem;
    width: 24px;
    height: 24px;
  }

  ${(_) => css`
    & ${InputFieldExtended.Root}:not(:placeholder-shown) + ${Label.Root} {
      transform: translateY(5px);
      font-size: 12px;
    }
    & ${InputFieldExtended.Root} {
      padding-bottom: 0px;
    }
    & ${InputFieldExtended.Root}::placeholder {
      color: transparent;
    }
  `}
`;

const InputWrapper = styled.input<{
  autoComplete?: string;
  check?: boolean;
  exclaim?: boolean;
  readOnly?: boolean;
}>`
  font-size: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid ${palette.gray600};
  border-radius: 4px;
  padding: 16px 16px;
  resize: none;
  background-color: var(--color-white);

  &[disabled] {
    cursor: not-allowed;
    border-color: transparent;
    background: ${palette.gray200};
    color: ${palette.gray800};
  }

  &:read-only {
    border-color: transparent;
    background: ${palette.gray200};
    color: ${palette.gray800};
  }

  ${(p) =>
    p.autoComplete === "one-time-code" &&
    css`
      /* Chrome, Safari, Edge, Opera */
      &[type="number"]::-webkit-outer-spin-button,
      &[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    `}

  ${(p) =>
    p.exclaim &&
    css`
      background-color: #fef7f6;
      border-color: var(--danger);
    `}
`;

InputField.displayName = "InputField";
const InputFieldExtended = Object.assign({}, InputField, {
  Root: InputWrapper,
});

export { InputFieldExtended as InputField };
