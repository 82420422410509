import { palette } from "@plantura-garden/palette";
import styled, { css } from "styled-components";

type Size = "default" | "small";
type Variant = "default" | "primary" | "light";

interface Props {
  children?: React.ReactNode;
  as?: "p" | "span" | "label";
  variant?: Variant;
  size?: Size;
  style?: object;
  className?: string;
}

const Text = ({
  children,
  as: element = "p",
  variant = "default",
  size = "default",
  style,
  className,
}: Props) => {
  return (
    <Wrapper
      as={element}
      style={style}
      className={className}
      variant={variant}
      size={size}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.p<{
  variant: Variant;
  size: Size;
}>`
  ${(p) => p.variant === "default" && defaultStyle};
  ${(p) => p.variant === "primary" && primaryStyle};
  ${(p) => p.variant === "light" && lightStyle};
  ${(p) => p.size === "default" && sizeDefaultStyle};
  ${(p) => p.size === "small" && sizeSmallStyle};
`;

const defaultStyle = css`
  color: var(--color-black);
`;

const primaryStyle = css`
  color: var(--color-primary);
  font-weight: 500;
  text-decoration: underline;

  --hover-color: ${palette.green800};

  &:hover {
    color: var(--hover-color);
  }
`;

const lightStyle = css`
  color: ${palette.gray800};
`;

const sizeDefaultStyle = css`
  font-size: 1rem;
`;

const sizeSmallStyle = css`
  font-size: 0.875rem;
`;

Text.Root = Wrapper;

export { Text };
