import styled from "styled-components";

import { Error } from "./error";

interface Props {
  children: React.ReactNode;
  label?: string;
}

const FormField = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  & ${Error.Root} {
    padding-left: 3px;
    padding-right: 3px;
    text-align: left;
  }
`;

FormField.Root = Wrapper;

export { FormField };
