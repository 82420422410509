import { palette } from "@plantura-garden/palette";

const ExclamationMark = ({ color }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={color || palette.danger500} />
      <path
        d="M12.5 6L12.5 14"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M12.5 18L12.5 18.0001"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export { ExclamationMark };
