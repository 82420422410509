import { palette } from "@plantura-garden/palette";
import styled from "styled-components";

interface Props extends React.ComponentProps<typeof StyledSeparator> {
  children: string;
  className?: string;
}

const Separator = (props: Props) => {
  return <StyledSeparator {...props} />;
};

const StyledSeparator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette.gray800};

  &:after,
  &:before {
    content: " ";
    height: 1px;
    background: ${palette.gray200};
    flex-grow: 1;
    margin-bottom: -3.5px;
  }

  &:after {
    margin-left: 0.75rem;
  }

  &:before {
    margin-right: 0.75em;
  }
`;

Separator.Root = StyledSeparator;

export { Separator };
