import React from "react";
import styled, { css } from "styled-components";

type Severity = "info" | "success" | "warning" | "error";

interface Props {
  title?: string;
  text: string;
  severity?: Severity;
}

const Alert = ({ title, text, severity = "info" }: Props) => {
  return (
    <Wrapper aria-live="polite" severity={severity}>
      {title && <Title>{title}</Title>}
      <Content>{text}</Content>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  severity: Severity;
}>`
  display: flex;
  flex-direction: column;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  ${(p) => p.severity === "info" && SeverityInfo}
  ${(p) => p.severity === "success" && SeveritySuccess}
  ${(p) => p.severity === "warning" && SeverityWarning}
  ${(p) => p.severity === "error" && SeverityError}
`;

const SeverityInfo = css`
  background: rgb(229, 246, 253);
`;

const SeveritySuccess = css`
  background: rgb(237, 247, 237);
`;

const SeverityWarning = css`
  background: rgb(255, 244, 229);
`;

const SeverityError = css`
  background: rgb(253, 237, 237);
`;

const Title = styled.div`
  font-weight: 500;
`;

const Content = styled.div``;

Alert.Root = Wrapper;

export { Alert };
