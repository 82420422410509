import {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  VerificationFlow,
  UiText,
} from "@ory/client";
import { isUiNodeInputAttributes } from "@ory/integrations/ui";
import { AxiosError } from "axios";
import { fromUnixTime, differenceInMinutes, isAfter } from "date-fns";
import { NextRouter } from "next/router";
import { useForm } from "react-hook-form";

import i18n from "~/i18n";
import { bugsnag } from "~/libs/api/bugsnag";

type Flow =
  | LoginFlow
  | RegistrationFlow
  | SettingsFlow
  | RecoveryFlow
  | VerificationFlow;

export const setUriFlow = (router: NextRouter, id?: string) => {
  if (router.query.flow === id) return;

  return router.push(
    {
      href: router.pathname,
      query: { ...router.query, flow: id },
    },
    undefined,
    { shallow: true },
  );
};

export function handleFlowError(
  router: NextRouter,
  flowType: "login" | "registration" | "settings" | "recovery" | "verification",
) {
  return async (err: AxiosError) => {
    switch ((err.response?.data as any)?.error?.id) {
      case "session_already_available":
        // User is already signed in, let's redirect them home!
        await router.push(`/api/redirect`);
        return;

      case "session_refresh_required":
        // We need to re-authenticate to perform this action
        window.location.href = (err.response?.data as any).redirect_browser_to;
        return;

      case "self_service_flow_return_to_forbidden":
        // The flow expired, let's request a new one.
        bugsnag.notify("Self service flow return_to forbidden");
        await router.push("/" + flowType);
        return;

      case "self_service_flow_expired": {
        const { reason } = (err.response?.data as any)?.error;

        // The flow expired, let's request a new one.
        await router.push({
          href: "/" + flowType,
          query: { error: reason },
        });

        return;
      }

      case "security_csrf_violation": {
        const { reason } = (err.response?.data as any)?.error;

        // A CSRF violation occurred. Best to just refresh the flow!
        await router.push({
          href: "/" + flowType,
          query: { error: reason },
        });

        return;
      }

      case "security_identity_mismatch":
        // The requested item was intended for someone else. Let's request a new flow...
        await router.push("/" + flowType);
        return;

      case "browser_location_change_required":
        // Ory Kratos asked us to point the user to this URL.
        window.location.href = (err.response?.data as any).redirect_browser_to;
        return;
    }

    switch (err.response?.status) {
      // flow expired
      case 410:
        await router.push("/");
    }
  };
}

export const setFormValues = (
  form: ReturnType<typeof useForm<any>>,
  flow: Flow,
) => {
  flow.ui.nodes.forEach((node) => {
    const { attributes } = node;

    if (isUiNodeInputAttributes(attributes)) {
      form.setValue(attributes.name, attributes.value);
    }
  });
};

export const setFormErrors = (
  form: ReturnType<typeof useForm<any>>,
  flow: Flow,
) => {
  flow.ui.nodes.forEach((node) => {
    const { attributes, messages } = node;

    if (isUiNodeInputAttributes(attributes) && messages.length) {
      const message = uiTextFormattedMessage(messages[0]);

      return form.setError(attributes.name, {
        message,
      });
    }
  });
};

export const uiTextFormattedMessage = (uiText: UiText) => {
  const language = i18n.language;
  const intlNumber = new Intl.NumberFormat(language, { signDisplay: "never" });
  const intlDateTime = new Intl.DateTimeFormat(language);
  const intlList = new Intl.ListFormat(language);

  const { id, context, text } = uiText;
  const message = i18n.t(`identities.messages.${id}`, text).toString();

  if (!hasContext(context)) {
    return message;
  }

  const contextExtended = Object.entries(context).reduce(
    (store, [key, value]) => {
      if (Array.isArray(value)) {
        return {
          ...store,
          [key]: store,
          [key + "_list"]: intlList.format(value),
        };
      }

      if (key.endsWith("_unix")) {
        return {
          ...store,
          [key]: intlDateTime.format(fromUnixTime(value)),
          ...(isAfter(new Date(), fromUnixTime(value))
            ? {
                [key + "_since"]: intlDateTime.formatRange(
                  fromUnixTime(value),
                  new Date(),
                ),
                [key + "_since_minutes"]: intlNumber.format(
                  differenceInMinutes(fromUnixTime(value), new Date()),
                ),
              }
            : {
                [key + "_until"]: intlDateTime.formatRange(
                  new Date(),
                  fromUnixTime(value),
                ),
                [key + "_until_minutes"]: intlNumber.format(
                  differenceInMinutes(new Date(), fromUnixTime(value)),
                ),
              }),
        };
      }

      return store;
    },
    context,
  );

  return Object.keys(contextExtended).reduce(
    (message: string, currentContext) => {
      return message.replaceAll(
        `{${currentContext}}`,
        contextExtended[currentContext],
      );
    },
    message,
  );
};

const hasContext = (
  context: object | undefined,
): context is Record<string, any> => {
  return typeof context === "object";
};
