import { palette } from "@plantura-garden/palette";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

type Variant = "default" | "light" | "link";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | React.ReactNode;
  as?: "button" | "a";
  variant?: Variant;
  type?: "button" | "submit";
  loading?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  ref?: React.RefObject<HTMLButtonElement>;
}

type LoaderVariant = "dark" | "light";

interface LoaderProps {
  variant: LoaderVariant;
}

const Loader = ({ variant }: LoaderProps) => {
  return (
    <StyledLoader variant={variant}>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </StyledLoader>
  );
};

const StyledLoader = styled.div<{
  variant: LoaderVariant;
}>`
  display: flex;

  & .dot {
    width: 9px;
    height: 9px;
    background-color: ${(p) =>
      p.variant === "dark"
        ? "var(--color-black);"
        : p.variant === "light"
          ? "var(--color-white)"
          : "transparent"};
    border-radius: 50%;
    animation: loading 2s infinite ease-in-out;
  }

  & > * + * {
    margin-inline-start: 0.5rem;
  }

  & .dot:nth-child(1) {
    animation-delay: 0s;
  }

  & .dot:nth-child(2) {
    animation-delay: 0.3s;
  }

  & .dot:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes loading {
    0%,
    80%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
`;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = "default",
      as: element,
      loading,
      startIcon,
      endIcon,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <Wrapper
        as={element}
        ref={ref}
        variant={variant}
        busy={loading}
        {...props}>
        {loading ? (
          <Loader
            key="loader"
            variant={variant === "light" ? "dark" : "light"}
          />
        ) : (
          [startIcon, children, endIcon]
        )}
      </Wrapper>
    );
  },
);

/**
 * Styles
 */

const Wrapper = styled.button<{
  disabled?: boolean;
  fullWidth?: boolean;
  busy?: boolean;
  variant: Variant;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  pointer-events: ${(p) => (p.busy ? "none" : "auto")};
  width: ${(p) => p.fullWidth && "100%"};
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  ${(p) => p.variant === "default" && defaultStyle}
  ${(p) => p.variant === "light" && lightStyle}
  ${(p) => p.variant === "link" && linkStyle}
`;

const defaultStyle = css`
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);

  --hover-color: ${palette.green700};

  &:hover {
    background-color: var(--hover-color);
    border-color: var(--hover-color);
  }

  &[disabled] {
    background-color: ${palette.gray700};
  }
`;

const lightStyle = css`
  background-color: var(--color-white);
  border-color: ${palette.gray800};
  color: ${palette.gray800};

  &:hover {
    background-color: var(--white);
    color: ${palette.gray800};
  }

  &[disabled] {
    background: ${palette.gray200};
    color: var(--color-white);
  }
`;

const linkStyle = css`
  background: unset;
  border-color: unset;
  color: var(--primary-high-contrast);

  &:hover {
    color: var(--primary-high-contrast);
    text-decoration: underline;
  }

  &[disabled] {
    color: ${palette.gray600};
  }
`;

/**
 * Issue: React forwardRef with custom static props
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-1008349828
 */

Button.displayName = "Button";
const ButtonExtended = Object.assign({}, Button, { Root: Wrapper });

export { ButtonExtended as Button };
