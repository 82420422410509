import { palette } from "@plantura-garden/palette";
import { createGlobalStyle } from "styled-components";

import { dinPro } from "./fonts";

export default createGlobalStyle`
    :root {
      --base-font-size: 16px;
      --base-font-family: ${dinPro.style.fontFamily}, sans-serif;
      --base-line-height: 1.5;
      --background: ${palette.white};
      --background-secondary: #f5f5f5;
      --background-dark: ${palette.black};
      --background-secondary-dark: #282828;

      --color-primary: ${palette.green500};
      --color-primary-high-contrast: #374c2f;
      --color-black: #000;
      --color-white: #fff;

      --danger: ${palette.danger500};
      --success: ${palette.green500};
      --primary-lighter: ${palette.green50};
      --primary-light: ${palette.green200};
      --primary: ${palette.green500};
      --primary-dark: ${palette.green700};
      --secondary-lighter: ${palette.beige50};
      --secondary-light: ${palette.beige500};
      --secondary: ${palette.beige100};
      --secondary-dark: ${palette.beige600};
      --purple-light: ${palette.purple300};
      --purple: ${palette.purple500};
      --purple-dark: ${palette.purple700};
      --gray-dark: ${palette.gray200};
    }

  * {
    margin: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body {
    background: var(--background-secondary);
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
    font-weight: 400;
    line-height: var(--base-line-height);
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  a {
    text-decoration: none;
  }
`;
