import React from "react";
import styled, { css } from "styled-components";

type Variant = "default";

interface Props {
  children: React.ReactNode;
  className?: string;
  variant?: Variant;
  style?: object;
}

const Card = ({
  children,
  variant = "default",
  style,
  className,
  ...props
}: Props) => {
  return (
    <Wrapper variant={variant} style={style} className={className} {...props}>
      {children}
    </Wrapper>
  );
};

/**
 * Styles
 */

const Wrapper = styled.div<{
  variant: Variant;
}>`
  padding-inline: 0rem;

  @media (max-width: 480px) {
    padding-inline: 1rem;
  }

  ${(p) => p.variant === "default" && defaultStyles}
`;

const defaultStyles = css``;

Card.Root = Wrapper;

export { Card };
