import styled from "styled-components";

interface Props {
  id?: string;
  children?: React.ReactNode;
}

const Error = ({ id, children }: Props) => {
  return <Wrapper id={id}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: var(--danger);
  padding: 0.375rem 0;
`;

Error.Root = Wrapper;

export { Error };
